import { AxiosResponse } from 'axios';
import { KAMINO_API_ENABLED, KaminoPageType } from 'constants/kamino';
import { KaminoApiClient } from 'helpers/KaminoApiClient';
import { KaminoCreatives } from 'types/Kamino';
import { log } from 'utils/loggerUtil';

export type KaminoBaseParams = {
  language: string;
  retailer: string;
  'user[gdpr_consent]': string;
  'user[user_id]': string;
};

export type HomePageParams = {
  page_type: 'HomePage';
};

export type ContentPageParams = {
  page_id: string;
  page_type: 'Universe';
};

export type CategoryPageParams = {
  page_id: string;
  page_type: 'Category';
};

export type SearchPageParams = {
  keywords: string;
  page_type: 'Search';
};

export type ProductPageParams = {
  page_id: string;
  page_type: 'ProductPage';
};

export type FetchKaminoCreativesConfig =
  | HomePageParams
  | ContentPageParams
  | CategoryPageParams
  | SearchPageParams
  | ProductPageParams;

export type FetchKaminoCreativesParams = FetchKaminoCreativesConfig & KaminoBaseParams;

export const fetchKaminoCreatives = async (params: FetchKaminoCreativesParams) => {
  if (!KAMINO_API_ENABLED || !params) return;

  try {
    const { data } = (await KaminoApiClient({
      params,
      url: '/creatives/fill-grid',
    })) as AxiosResponse<KaminoCreatives>;

    return data;
  } catch (error) {
    log('Criteo pushViewEvent', 'Something went wrong pushing view analytics', error);
    throw error;
  }
};

export type PushKaminoBeaconParams = {
  act?: 'product_page' | 'add_to_cart' | 'add_to_list' | 'step' | 'redirect' | 'nav-next' | 'nav-prev';
  click_zone?: 'product' | 'format' | 'video';
  product_id?: string;
  stoptime?: 0 | 1 | 2;
  'user[gdpr_consent]': string;
  'user[user_id]': string;
};

export const pushKaminoBeacon = async (beacon: string, params?: PushKaminoBeaconParams) => {
  if (!KAMINO_API_ENABLED) return;
  try {
    if (!beacon) throw new Error('No beacon passed to push');

    const beaconUrl = `${beacon.startsWith('//') ? 'https:' : ''}${beacon}`;

    return await KaminoApiClient({
      method: 'POST',
      params,
      url: beaconUrl,
    });
  } catch (error) {
    log('Kamino pushKaminoBeacon', 'Something went wrong pushing beacon analytics', error);
  }
};

export type PushKaminoTrackingBuyParams = {
  cart: {
    order_id: string;
    products: {
      id: string;
      price: string;
      quantity: number;
    }[];
  };
  context: {
    page_type: KaminoPageType;
    retailer: string;
    user: {
      gdpr_consent: string;
      user_id: string;
    };
  };
};

export const pushKaminoTrackingBuy = async (params: PushKaminoTrackingBuyParams) => {
  if (!KAMINO_API_ENABLED) return;

  try {
    const { data } = await KaminoApiClient({
      data: params,
      method: 'POST',
      url: '/tracking/buy',
    });

    return data;
  } catch (error) {
    log('Kamino pushKaminoTrackingBuy', 'Something went wrong pushing /tracking/buy', error);
    throw error;
  }
};

export type PushKaminoTrackingAddToCartParams = {
  prd: string;
  price: string;
  pt: KaminoPageType;
  qty: number;
  r: string;
  'user[gdpr_consent]': string;
  'user[user_id]': string;
};

export const pushKaminoTrackingAddToCart = async (params: PushKaminoTrackingAddToCartParams) => {
  if (!KAMINO_API_ENABLED) return;

  try {
    const { data } = await KaminoApiClient({
      method: 'POST',
      params,
      url: '/tracking/abp',
    });

    return data;
  } catch (error) {
    log('Kamino pushKaminoTrackingAddToCart', 'Something went wrong pushing /tracking/abp', error);
    throw error;
  }
};
