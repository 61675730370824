import { logErrorResponse } from 'utils/loggerUtil';

export const fetchVisitorId = async () => {
  try {
    const result = await fetch('/api/visitorId', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    const data = (await result.json()) as { visitorId: string };

    return data.visitorId;
  } catch (error) {
    logErrorResponse('Site Connector', error, 'Error fetching visitorId');
  }
};
