import { KAMINO_RETAILER_ID, KaminoPageType } from 'constants/kamino';
import {
  pushKaminoTrackingAddToCart,
  PushKaminoTrackingAddToCartParams,
  pushKaminoTrackingBuy,
  PushKaminoTrackingBuyParams,
} from 'features/kamino/connector';
import { TrackingEvents } from 'hooks/useTrackingEvents';
import { PathnameType } from 'i18n/pathnames';
import { OrderEntry } from 'types/Order';
import { log } from 'utils/loggerUtil';
import { nonNullable } from 'utils/typeUtil';

const mapOrderEntryToKaminoProduct = (
  entry: OrderEntry,
): PushKaminoTrackingBuyParams['cart']['products'][0] | undefined => {
  if (!entry.product) return;

  return {
    id: entry.product.code,
    price: entry.totalProductPrice?.value?.toString() ?? '0',
    quantity: entry.quantity ?? 0,
  };
};

const mapPathnameToKaminoPageType = (pathname: PathnameType): KaminoPageType => {
  switch (pathname) {
    case '/p/[id]':
      return 'ProductPage';
    case '/c/[...slug]':
      return 'Category';
    case '/search':
      return 'Search';
    case '/':
      return 'HomePage';
    case '/[slug]':
      return 'Universe';
    case '/checkout/confirmation':
      return 'ConfirmationPage';
    case '/cart':
      return 'Shop';
    default:
      return 'CustomList';
  }
};

const KaminoTrackingHelper: Partial<TrackingEvents> = {
  onAddToCart: async ({ pathname, product, visitorId }) => {
    const params: PushKaminoTrackingAddToCartParams = {
      prd: product.code,
      price: product.price?.value?.toString() ?? '0',
      pt: mapPathnameToKaminoPageType(pathname),
      qty: product.quantity ?? 1,
      r: KAMINO_RETAILER_ID,
      'user[gdpr_consent]': 'true',
      'user[user_id]': visitorId ?? '',
    };
    log('Kamino tracking', 'onAddToCart - params', params);
    await pushKaminoTrackingAddToCart(params);
  },
  onOrderConfirmationView: async ({ order, visitorId }) => {
    const params: PushKaminoTrackingBuyParams = {
      cart: {
        order_id: order.code,
        products: order.entries?.map(mapOrderEntryToKaminoProduct).filter(nonNullable) ?? [],
      },
      context: {
        page_type: 'ConfirmationPage',
        retailer: KAMINO_RETAILER_ID,
        user: {
          gdpr_consent: 'true',
          user_id: visitorId ?? '',
        },
      },
    };
    log('Kamino tracking', 'onOrderConfirmationView - params', params);
    await pushKaminoTrackingBuy(params);
  },
};

export default KaminoTrackingHelper;
