import { isWebview } from 'constants/environment';
import { pushKaminoBeacon, PushKaminoBeaconParams } from 'features/kamino/connector';
import { useVisitorId } from 'features/site/queries';
import { useCactConsentGroupAccepted } from './useCactConsentGroupAccepted';

export const useKaminoTracking = () => {
  const consentAccepted = useCactConsentGroupAccepted('TARGETING');
  const { data: visitorId } = useVisitorId();
  const trackingAllowed = consentAccepted && !isWebview;
  const _pushKaminoBeacon = trackingAllowed
    ? (beacon: string, params?: Omit<PushKaminoBeaconParams, 'user[gdpr_consent]' | 'user[user_id]'>) =>
        pushKaminoBeacon(beacon, {
          ...params,
          'user[gdpr_consent]': consentAccepted ? 'true' : 'false',
          'user[user_id]': visitorId ?? '',
        })
    : undefined;
  return { pushKaminoBeacon: _pushKaminoBeacon, userHasSufficientConsent: trackingAllowed, visitorId };
};
