import { AnalyticsPageType, PianoPageName, PianoPageType } from 'constants/analytics';
import ABTastyTrackingHelper from 'helpers/ABTastyTrackingHelper';
import KaminoTrackingHelper from 'helpers/KaminoTrackingHelper';
import PianoTrackingHelper from 'helpers/PianoTrackingHelper';
import { Pathname } from 'i18n/pathnames';
import { AlgoliaProduct } from 'types/Algolia';
import { Cart } from 'types/Cart';
import { Category } from 'types/Category';
import { Order } from 'types/Order';
import { Product } from 'types/Product';
import { Store } from 'types/Store';
import { User } from 'types/User';
import { nonNullable } from 'utils/typeUtil';
import { useKaminoTracking } from './useKaminoTracking';

type PageViewParams = {
  analyticsPageName?: AnalyticsPageType | PianoPageName;
  analyticsPageType?: AnalyticsPageType | PianoPageType;
  category?: Category;
  locale: string;
  path: string;
  pathname: string;
  product?: Product;
  slug?: string;
  store?: Store;
  user?: User;
};

type PDPViewParams = {
  product: Product;
};

type PLPViewParams = {
  category?: Category;
  hits?: AlgoliaProduct[];
  pageNumber?: number;
  products?: Product[];
};

type SearchResultViewParams = {
  amountOfResults: number;
  keyword: string;
  pageNumber: number;
};

type CartViewParams = {
  cart: Cart;
};

type CartDeliveryViewParams = {
  cart: Cart;
};

type CartPaymentViewParams = {
  cart: Cart;
};

type OrderConfirmationParams = {
  order: Order;
  visitorId?: string;
};

type AddToCartParams = {
  cart: Cart;
  pathname: Pathname;
  product: Product;
  visitorId?: string;
};

type CartCreationParams = {
  cart: Cart;
};

type RemoveFromCartParams = {
  cart: Cart;
  product: Product;
};

type OnComponentViewParams = {
  campaign: string;
  component: string;
  path: string;
};

type OnComponentClickParams = OnComponentViewParams;

export type TrackingEvents = {
  onAddToCart: (payload: AddToCartParams) => void;
  onCartCreation: (payload: CartCreationParams) => void;
  onCartDeliveryView: (payload: CartDeliveryViewParams) => void;
  onCartPaymentView: (payload: CartPaymentViewParams) => void;
  onCartView: (payload: CartViewParams) => void;
  onComponentClick: (payload: OnComponentClickParams) => void;
  onComponentView: (payload: OnComponentViewParams) => void;
  onOrderConfirmationView: (payload: OrderConfirmationParams) => Promise<void>;
  onPDPView: (payload: PDPViewParams) => void;
  onPLPView: (payload: PLPViewParams) => void;
  onPageView: (payload: PageViewParams) => Promise<void>;
  onRemoveFromCart: (payload: RemoveFromCartParams) => void;
  onSearchResultView: (payload: SearchResultViewParams) => void;
};

const useTrackingEvents = () => {
  const { userHasSufficientConsent } = useKaminoTracking();
  const kaminoTrackingHelper = userHasSufficientConsent ? KaminoTrackingHelper : undefined;
  const listeners: Partial<TrackingEvents>[] = [
    PianoTrackingHelper,
    ABTastyTrackingHelper,
    kaminoTrackingHelper,
  ].filter(nonNullable);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = (event: keyof TrackingEvents, payload?: any) => {
    listeners.forEach((listener) => listener[event]?.(payload));
  };

  const onPageView = (payload: PageViewParams) => {
    dispatch('onPageView', payload);
  };

  const onPDPView = (payload: PDPViewParams) => {
    dispatch('onPDPView', payload);
  };

  const onPLPView = (payload: PLPViewParams) => {
    dispatch('onPLPView', payload);
  };

  const onSearchResultView = (payload: SearchResultViewParams) => {
    dispatch('onSearchResultView', payload);
  };

  const onCartView = (payload: CartViewParams) => {
    dispatch('onCartView', payload);
  };

  const onCartDeliveryView = (payload: CartDeliveryViewParams) => {
    dispatch('onCartDeliveryView', payload);
  };

  const onCartPaymentView = (payload: CartPaymentViewParams) => {
    dispatch('onCartPaymentView', payload);
  };

  const onOrderConfirmationView = (payload: OrderConfirmationParams) => {
    dispatch('onOrderConfirmationView', payload);
  };

  const onAddToCart = (payload: AddToCartParams) => {
    dispatch('onAddToCart', payload);
  };

  const onCartCreation = (payload: CartCreationParams) => {
    dispatch('onCartCreation', payload);
  };

  const onRemoveFromCart = (payload: RemoveFromCartParams) => {
    dispatch('onRemoveFromCart', payload);
  };

  const onComponentView = (payload: OnComponentViewParams) => {
    dispatch('onComponentView', payload);
  };

  const onComponentClick = (payload: OnComponentClickParams) => {
    dispatch('onComponentClick', payload);
  };

  return {
    onAddToCart,
    onCartCreation,
    onCartDeliveryView,
    onCartPaymentView,
    onCartView,
    onComponentClick,
    onComponentView,
    onOrderConfirmationView,
    onPDPView,
    onPLPView,
    onPageView,
    onRemoveFromCart,
    onSearchResultView,
  } as TrackingEvents;
};

export default useTrackingEvents;
