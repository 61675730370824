import { ANALYTIC_EVENTS, AnalyticEvent } from 'constants/analytics';
import { APP_EVENT_MESSAGES } from 'constants/appEvents';
import { NOTIFICATION_TYPES } from 'constants/notifications';
import { useVisitorId } from 'features/site/queries';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import { Cookies } from 'react-cookie';
import { IntlShape } from 'react-intl';
import { useDispatch } from 'react-redux';
import { type Dispatch } from 'redux';
import { addConfigurationToCart } from 'redux/modules/configurator';
import { getProductByCode } from 'redux/modules/product';
import { useAddToCartStoreActions } from 'store/addToCart';
import { useNotificationStore } from 'store/notification';
import { AppEventParams } from 'types/AppEvent';
import { Cart, CartEntry } from 'types/Cart';
import { Product } from 'types/Product';
import { pushAddToCartEvent, pushRemoveFromCartEvent } from 'utils/analyticsUtil';
import { addSalesForceAddToCartEvent, removeCartCookie } from 'utils/cartUtil';
import { log } from 'utils/loggerUtil';
import { createNotification } from 'utils/notifications';
import config from '../config';
import { pathnames, PathnameType } from '../i18n/pathnames';
import {
  addProductToCart,
  clearCart,
  createCart,
  getAbandonedCart,
  getCart,
  removeEntry,
  removeProduct,
} from '../redux/modules/cart';
import useAuthResolver from './useAuthResolver';
import { useRouterParam } from './useRouterParam';
import useTrackingEvents from './useTrackingEvents';

type AddToCartProps = {
  analyticsList?: string;
  bundleCode?: string;
  callback?: CallableFunction;
  event?: AnalyticEvent;
  formatMessage: IntlShape['formatMessage'];
  hasSelectedExtraWarranty?: boolean;
  onCartCreation?: ReturnType<typeof useTrackingEvents>['onCartCreation'];
  position?: number;
  product?: Product;
  productCodes?: string[];
  sendAppEvent?: (vals: AppEventParams) => void;
};

// @FIXME: The whole cart behaviour needs to be revised as this is not maintainable
const useCart = () => {
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const abandonedCartToken = useRouterParam('token');
  const isCheckoutPage = pathname.includes('checkout');
  const cartGuidParam = useRouterParam('id');
  const { data: visitorId } = useVisitorId();
  const { anonymous } = useAuthResolver();

  const { onAddToCart: onAddToCartEvent, onRemoveFromCart } = useTrackingEvents();
  const { queueNotification } = useNotificationStore.getState().actions;
  const { setShowAddToCartSheet } = useAddToCartStoreActions();

  const cart = useSelector((state) => state.cart.cart);
  const isInitializedCart = useSelector((state) => state.cart.isInitializedCart);
  const isFetchingCart = useSelector((state) => state.cart.isFetchingCart);
  const isUpdatingCart = useSelector((state) => state.cart.isUpdatingCart);

  const cookies = new Cookies();
  const anonymousCartId = cookies.get(config.cart.key);

  const initCart = async () => {
    const cartGuid = isCheckoutPage ? cartGuidParam : undefined;
    const isCartPage = pathname === pathnames.CART;

    try {
      if (abandonedCartToken && isCartPage) {
        await dispatch(getAbandonedCart(abandonedCartToken));
        return;
      }
      if (anonymous && !anonymousCartId && !isCartPage) return;
      if (!anonymous && anonymousCartId) {
        await dispatch(createCart(anonymous, anonymousCartId));
        cookies.remove(config.cart.key, config.cookie);
      } else {
        await dispatch(getCart(anonymous, anonymousCartId, cartGuid));
      }
    } catch (e) {
      log('initCart - CartUtil', 'Error initializing cart', e);
    }
  };

  const onAddToCart = async ({
    analyticsList,
    bundleCode,
    callback,
    event = ANALYTIC_EVENTS.ADD_TO_CART,
    formatMessage,
    hasSelectedExtraWarranty = false,
    onCartCreation,
    position,
    product,
    productCodes,
    sendAppEvent,
  }: AddToCartProps) => {
    if (cart && product) {
      onAddToCartEvent({ cart, pathname: pathname as PathnameType, product, visitorId });
    }
    product && pushAddToCartEvent(event, product, position, analyticsList);

    try {
      const data = await dispatch(
        // @ts-ignore
        addProductToCart(
          cart,
          product,
          anonymous,
          null,
          null,
          hasSelectedExtraWarranty,
          productCodes,
          bundleCode,
          undefined,
          onCartCreation,
        ),
      );
      // @ts-ignore
      const updatedCart = data.data as Cart;

      if (sendAppEvent) {
        sendAppEvent({ message: APP_EVENT_MESSAGES.CART_UPDATED });
      }

      if (updatedCart?.entries) {
        addSalesForceAddToCartEvent(updatedCart.entries);
      }

      setShowAddToCartSheet(true);

      callback?.();
    } catch (err) {
      const errorMessage = (err as { message: string })?.message;
      const notification = createNotification({
        notification: {
          message: errorMessage ? formatMessage({ id: errorMessage }) : errorMessage,
        },
        type: NOTIFICATION_TYPES.ERROR,
      });
      queueNotification(notification);
    }
  };

  const onRemoveProduct = async (
    dispatch: Dispatch,
    cart: Cart,
    anonymous: boolean,
    product: Product,
    event: AnalyticEvent = ANALYTIC_EVENTS.REMOVE_FROM_CART,
    sendAppEvent: (vals: AppEventParams) => void,
  ) => {
    onRemoveFromCart({ cart, product });
    pushRemoveFromCartEvent(event, product);

    try {
      // @ts-ignore
      const result = await dispatch(removeProduct(product, anonymous, cart.guid));
      sendAppEvent({ message: APP_EVENT_MESSAGES.CART_UPDATED });
      const tempCart = result?.data;

      if (tempCart?.entries?.length > 0) {
        addSalesForceAddToCartEvent(cart.entries);
      } else {
        dispatch(clearCart());
        if (window._etmc) {
          config.salesForce.orgId && window._etmc.push(['setOrgId', config.salesForce.orgId]);
          window._etmc.push(['trackCart', { clear_cart: true }]);
        }
      }
    } catch (error) {
      log('onRemoveProduct - CartUtil', 'Error removing product from cart', error);
    }
  };

  const onRemoveEntry = async (
    dispatch: Dispatch,
    cart: Cart,
    anonymous: boolean,
    entry: CartEntry,
    event: AnalyticEvent = ANALYTIC_EVENTS.REMOVE_FROM_CART,
    sendAppEvent: (vals: AppEventParams) => void,
  ) => {
    try {
      // @ts-ignore
      await dispatch(removeEntry(entry, anonymous, cart.guid)).then((result) => {
        const newCart = result?.data;
        if (!newCart?.guid && anonymous) {
          removeCartCookie();
        }
        if (entry?.product) {
          pushRemoveFromCartEvent(event, entry.product);
          onRemoveFromCart({ cart, product: entry.product });
        }
      });
      sendAppEvent({ message: APP_EVENT_MESSAGES.CART_UPDATED });
    } catch (e) {
      log('onRemoveEntry - CartUtil', 'Error removing entry from cart', e);
    }
  };

  type OnAddConfigurationToCartParams = {
    anonymous: boolean;
    cart?: Cart;
    configurationUid: string;
    dispatch: Dispatch;
    sendAppEvent: (vals: AppEventParams) => void;
  };

  const onAddConfigurationToCart = async ({
    anonymous,
    cart,
    configurationUid,
    dispatch,
    sendAppEvent,
  }: OnAddConfigurationToCartParams) => {
    const { queueNotification } = useNotificationStore.getState().actions;

    try {
      // @ts-ignore
      await dispatch(addConfigurationToCart(anonymous, cart, configurationUid));
      const notification = createNotification({
        type: NOTIFICATION_TYPES.BASKET,
      });
      queueNotification(notification);
      sendAppEvent({ message: APP_EVENT_MESSAGES.CART_UPDATED });
    } catch (err) {
      const errorMessage = (err as { message: string }).message;
      const notification = createNotification({
        notification: { message: errorMessage },
        type: NOTIFICATION_TYPES.ERROR,
      });
      queueNotification(notification);
    }
  };

  const addProductsToCart = async ({
    callback,
    codes,
    formatMessage,
  }: {
    callback?: CallableFunction;
    codes: string[];
    formatMessage: IntlShape['formatMessage'];
  }) => {
    const productCodes = [...codes];
    const productCode = productCodes[0];

    if (productCodes?.length > 0 && productCode) {
      try {
        const { data: product } = await dispatch(getProductByCode(productCode) as never);
        await onAddToCart({
          formatMessage,
          product,
        });
        productCodes.shift();

        if (productCodes.length === 0 && callback) {
          callback();
        }

        if (productCodes.length >= 1) {
          await addProductsToCart({ callback, codes: productCodes, formatMessage });
        }
      } catch (e) {
        log('cart', `Product with code ${productCodes} is not added to cart`, e);
      }
    }
  };

  return {
    addProductsToCart,
    anonymousCartId,
    cart,
    initCart,
    isFetchingCart,
    isInitializedCart,
    isUpdatingCart,
    onAddConfigurationToCart,
    onAddToCart,
    onRemoveEntry,
    onRemoveProduct,
  };
};

export default useCart;
